import React from 'react';
import './assets/scss/style.scss'
import Home from "./components/Home";

function App() {
    return (
        <>
            <Home />
        </>
    )
}

export default App
